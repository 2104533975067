@import "bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-page-color{
  background-color: #000000;
}
.nav-md{
  height: 100px;
  width: 1200px;
  margin: auto;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
}

.logo-div{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo{
  font-size: 30px;
  font-family: 'inria-serif';
  color: #fff;
}

#nav-links>a{
  text-decoration: none;
  font-family: 'inria-serif';
  color: #fff;
  font-size: 24px;
  z-index: 100;
}

.nav-link-button-text{
  margin: 0;
  padding: 20px 10px 20px 10px;
}

.my-image{
  margin-top: 2.5rem;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-image: url("../src/Images/me.jpg");
  background-size: contain;
}

#main-page-content{
  width: 60%;
  margin: auto;
}

#about-head{
    font-size: 40px;
    font-family: 'bree-serif';
    color: #fff;
    letter-spacing: 3px;
    padding: 0;
    margin: 0;
}

#about-content>p{
  font-size: 20px;
  font-family: 'bree-serif';
  color: #fff;
  letter-spacing: 3px;
}

#about-me{
  margin-top: 3rem;
}
@media (max-width: 768px) {
  #main-page-content{
    width: 80% !important;
  }

  #about-content>p{
    font-size: 16px;
  }

  #about-head{
    font-size: 30px;
  }

  #nav-links>a{
    font-size: 16px;
    padding: .5rem;
    margin: 0;
    text-align: center;
  }
}

@media (max-width: 1200px){
  .nav-md{
    width: 90%;
  }
  #nav-links{
    width: 75% !important;
  }
}


#hi{
  font-size: 24px;
}

.nav-sm{
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 5px;
}

@media (height <= 992px) {
  #Buttons{
    width: 50% !important;
  }
}

.card-img-size{
  height: 300px;
}

.my-button{
  width: 40%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 3px solid white;
  border-radius: 5px;
  text-decoration: none;
  color: white;
}

.my-button:hover{transform: scale(1.2);}
.card:hover{transform: scale(1.05);}

.card-size{
  width:400px;
  height: auto;
}

.card-body-text-size{
  height: 100px;
  overflow: hidden;
}
.card-img-darkness{
  filter: brightness(.4);
}

hr{
  color: white;
  width: 70%;
  margin: 2rem auto 2rem auto;
}

@media (max-width: 576px) {
  .card-size{
    width: 85%;
  }
  .card{
    margin-top: 1rem;
  }
}

.tehnology-box-size{
  width: 25%;
  height: 100%;
}

@media (max-width: 768px) {
  .tehnology-box-size{
    width: 50%;
  }
}

#tehnology-list{
  background-color: transparent;
  color: white;
}
